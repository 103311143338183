exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".SignUpBlock__root{-ms-flex:none;flex:none;background-color:#fff;width:100%;color:#323232;min-height:40vh;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column}.SignUpBlock__root .container{-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;height:100%;-ms-flex:1;flex:1}.SignUpBlock__root .SignUpBlock__prompt{-ms-flex:none;flex:none;font-size:2.5em;font-family:Alegreya Sans,sans-serif;font-weight:700;padding:0 0 1em;text-align:center}.SignUpBlock__root .SignUpBlock__tourButton{-ms-flex:none;flex:none;margin-top:2em}@media (min-width:768px){.SignUpBlock__root .SignUpBlock__prompt{font-size:3.5em}}", "", {"version":3,"sources":["/tmp/build_2847e9cb/web/js/components/homepage/widgets/SignUpBlock.scss"],"names":[],"mappings":"AAAA,mBAAM,cAAc,UAAU,sBAAsB,WAAW,cAAc,gBAAgB,oBAAoB,aAAa,0BAA0B,qBAAqB,CAAC,8BAA0B,sBAAsB,mBAAmB,qBAAqB,uBAAuB,YAAY,WAAW,MAAM,CAAC,wCAAc,cAAc,UAAU,gBAAgB,AAAgB,qCAAwC,gBAAgB,gBAAgB,iBAAiB,CAAC,4CAAkB,cAAc,UAAU,cAAc,CAAC,yBAA0B,wCAAc,eAAe,CAAC,CAAC","file":"SignUpBlock.scss","sourcesContent":[".root{-ms-flex:none;flex:none;background-color:#fff;width:100%;color:#323232;min-height:40vh;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column}.root :global(.container){-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;height:100%;-ms-flex:1;flex:1}.root .prompt{-ms-flex:none;flex:none;font-size:2.5em;font-weight:700;font-family:'Alegreya Sans', sans-serif;font-weight:700;padding:0 0 1em;text-align:center}.root .tourButton{-ms-flex:none;flex:none;margin-top:2em}@media (min-width: 768px){.root .prompt{font-size:3.5em}}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"root": "SignUpBlock__root",
	"prompt": "SignUpBlock__prompt",
	"tourButton": "SignUpBlock__tourButton"
};