exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".HamburgerMenu__root{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;color:#fff;cursor:pointer;font-size:12px;width:50px}.HamburgerMenu__root .HamburgerMenu__icon{line-height:1}.HamburgerMenu__root .HamburgerMenu__icon>img{width:100%;height:auto}.HamburgerMenu__root .HamburgerMenu__label{color:#19dcd5;text-transform:uppercase;margin-left:.35em;font-weight:500}.HamburgerMenu__root.HamburgerMenu__stacked{-ms-flex-direction:column-reverse;flex-direction:column-reverse}.HamburgerMenu__root.HamburgerMenu__stacked .HamburgerMenu__icon{font-size:2.5em}.HamburgerMenu__root.HamburgerMenu__stacked .HamburgerMenu__label{margin:0}.HamburgerMenu__root:hover .HamburgerMenu__label{color:#dbb602}", "", {"version":3,"sources":["/tmp/build_2847e9cb/web/js/components/homepage/elements/HamburgerMenu.scss"],"names":[],"mappings":"AAAA,qBAAM,oBAAoB,aAAa,uBAAuB,mBAAmB,sBAAsB,mBAAmB,qBAAqB,uBAAuB,WAAW,eAAe,eAAe,UAAU,CAAC,0CAAY,aAAa,CAAC,8CAAgB,WAAW,WAAW,CAAC,2CAAa,cAAc,yBAAyB,kBAAmB,eAAe,CAAC,4CAAc,kCAAkC,6BAA6B,CAAC,iEAAoB,eAAe,CAAC,kEAAqB,QAAQ,CAAC,iDAAmB,aAAa,CAAC","file":"HamburgerMenu.scss","sourcesContent":[".root{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;color:#fff;cursor:pointer;font-size:12px;width:50px}.root .icon{line-height:1}.root .icon>img{width:100%;height:auto}.root .label{color:#19dcd5;text-transform:uppercase;margin-left:0.35em;font-weight:500}.root.stacked{-ms-flex-direction:column-reverse;flex-direction:column-reverse}.root.stacked .icon{font-size:2.5em}.root.stacked .label{margin:0}.root:hover .label{color:#dbb602}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"root": "HamburgerMenu__root",
	"icon": "HamburgerMenu__icon",
	"label": "HamburgerMenu__label",
	"stacked": "HamburgerMenu__stacked"
};