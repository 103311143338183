

import {combineReducers} from "redux";
import * as ActionTypes from "constants/ActionTypes";
import cloneDeep from "lodash/cloneDeep";
import forEach from "lodash/forEach";
import keyBy from "lodash/keyBy";
import omit from "lodash/omit";

import * as FreeTrialTourStates from "constants/FreeTrialTourStates";
import filter from "lodash/filter";
import {ACTIVE_SITE_NOTIFICATION_DISMISSED, USER_EVALUATION_UPDATED} from "constants/ActionTypes";


const STANDARD_RESULTS_PER_PAGE = 5;

function windowSize(state=null, action) {
    switch (action.type) {
        case ActionTypes.WINDOW_SIZE_SET:
            return action.dimensions;
        default:
            return state;
    }
}

function renderMode(state={}, action) {
    switch (action.type) {
        case ActionTypes.WINDOW_SIZE_SET:
            return {
                mobile: action.dimensions.width < 900,
            };
        default:
            return state;
    }
}

function loadingFeed(state=false, action) {
    switch (action.type) {
        case ActionTypes.START_POST_FEED_LOAD:
        case ActionTypes.START_EVENTS_LOAD:
            return true;
        case ActionTypes.POST_FEED_LOADED:
        case ActionTypes.POST_FEED_LOAD_ERROR:
        case ActionTypes.FEATURED_POSTS_LOAD_ERROR:
        case ActionTypes.SCHEDULED_POSTS_LOADED:
        case ActionTypes.SCHEDULED_POSTS_LOAD_ERROR:
        case ActionTypes.VIDEO_ARCHIVE_LOADED:
        case ActionTypes.VIDEO_ARCHIVE_LOAD_ERROR:
        case ActionTypes.EVENTS_LOADED:
            return false;
        default:
            return state;
    }
}

function lastCommentId(state={}, action) {
    switch (action.type) {
        case ActionTypes.COMMENTS_LOADED:
            return action.commentResponse.lastCommentId;
        default:
            return state;
    }
}

function bustCache(state = false, action) {
    switch (action.type) {
        case ActionTypes.BUST_CACHE:
        case ActionTypes.REPLY_UPDATED:
        case ActionTypes.COMMENT_UPDATED:
            return true;
        case ActionTypes.CACHE_BUSTED:
        case ActionTypes.COMMENTS_RELOADED:
            return false;
        default:
            return state;
    }
}

function lastReplyIds(state={}, action) {
    switch (action.type) {
        case ActionTypes.COMMENTS_LOADED:
            const commentReplies = {};
            forEach(action.commentResponse.results, (c) => {
                const hasMore = !!c.replies && c.replies.results.length < c.replies.total;
                commentReplies[c.id] = hasMore ? c.replies.results[c.replies.results.length - 1].id : null;
            });
            return {
                ...state,
                ...commentReplies,
            };
        case ActionTypes.REPLIES_LOADED:
            return {
                ...state,
                [action.commentId]: action.replyResult.results.length < STANDARD_RESULTS_PER_PAGE ? null : action.replyResult.lastCommentId,
            };
        case ActionTypes.REPLIES_RELOADED:
            return state;
        default:
            return state;
    }
}

function numberOfReplies(state={}, action) {
    switch (action.type) {
        case ActionTypes.COMMENTS_LOADED:
            const commentReplies ={};
            forEach(action.commentResponse.results, (c) => {
                commentReplies[c.id] = c.replies && c.replies.results.length ? c.replies.total : 0;
            });
            return {
                ...state,
                ...commentReplies,
            };
        default:
            return state;
    }
}

function lastPostId(state=null, action) {
    switch (action.type) {
        case ActionTypes.POST_FEED_LOADED:
            return action.postResponse.lastPostId;
        case ActionTypes.VIDEO_ARCHIVE_LOADED:
            return action.videoArchive.lastPostId;
        default:
            return state;
    }
}

function lastEventId(state=null, action) {
    switch (action.type) {
        case ActionTypes.EVENTS_LOADED:
            return action.eventsResponse.lastEventId;
        default:
            return state;
    }
}

// DATABASE SEARCH -------------------------------------------------------------------------------------------- /
function searchPageLoadingState(state={}, action) {
    switch (action.type) {
        case ActionTypes.SEARCH_PAGE_QUERY_START:
            return {
                ...state,
                [action.query]: true,
            }
        case ActionTypes.USER_SEARCH_QUERIED:
            return {
                ...state,
                users: false,
            };
        case ActionTypes.POST_SEARCH_QUERIED:
            return {
                ...state,
                posts: false,
            };
        case ActionTypes.COMMENT_SEARCH_QUERIED:
            return {
                ...state,
                comments: false,
            };
        case ActionTypes.VIDEO_SEARCH_QUERIED:
            return {
                ...state,
                videos: false,
            };
        case ActionTypes.EVENT_SEARCH_QUERIED:
            return {
                ...state,
                events: false,
            };
        default:
            return state;
    }
}

function userSearchResults(state={}, action) {
    switch (action.type) {
        case ActionTypes.USER_SEARCH_QUERIED:
            return action.userSearchResults;
        default:
            return state;
    }
}

function postSearchResults(state={}, action) {
    switch (action.type) {
        case ActionTypes.POST_SEARCH_QUERIED:
            return action.postSearchResults;
        case ActionTypes.NEXT_POST_SEARCH_QUERIED:
            return {
                nextUrl: action.postSearchResults.nextUrl,
                results: state.results.concat(action.postSearchResults.results),
            };
        default:
            return state;
    }
}

function commentSearchResults(state={}, action) {
    switch (action.type) {
        case ActionTypes.COMMENT_SEARCH_QUERIED:
            return action.commentSearchResults;
        case ActionTypes.NEXT_COMMENT_SEARCH_QUERIED:
            return {
                nextUrl: action.commentSearchResults.nextUrl,
                results: state.results.concat(action.commentSearchResults.results),
            };
        default:
            return state;
    }
}

function videoSearchResults(state={}, action) {
    switch (action.type) {
        case ActionTypes.VIDEO_SEARCH_QUERIED:
            return action.videoSearchResults;
        case ActionTypes.NEXT_VIDEO_SEARCH_QUERIED:
            return {
                nextUrl: action.videoSearchResults.nextUrl,
                results: state.results.concat(action.videoSearchResults.results),
            };
        default:
            return state;
    }
}

function eventSearchResults(state={}, action) {
    switch (action.type) {
        case ActionTypes.EVENT_SEARCH_QUERIED:
            return action.eventSearchResults;
        case ActionTypes.NEXT_EVENT_SEARCH_QUERIED:
            return {
                nextUrl: action.eventSearchResults.nextUrl,
                results: state.results.concat(action.eventSearchResults.results),
            };
        default:
            return state;
    }
}
// -------------------------------------------------------------------------------------------- DATABASE SEARCH /


function savingUserProfile(state=false, action) {
    switch (action.type) {
        case ActionTypes.START_SAVE_USER_PROFILE:
        case ActionTypes.START_SAVE_USER_EMAIL:
            return true;
        case ActionTypes.CURRENT_USER_UPDATED:
        case ActionTypes.USER_PROFILE_SAVED_ERROR:
            return false;
        default:
            return state;
    }
}

function creatingUserEvaluation(state=false, action) {
    switch (action.type) {
        case ActionTypes.START_CREATE_USER_EVALUATION:
            return "processing";
        case ActionTypes.USER_EVALUATION_CREATED:
            return "created"
        case ActionTypes.USER_EVALUATION_CREATED_ERROR:
            return "error";
        default:
            return state;
    }
}


function currentStudio(state=null, action) {
    switch (action.type) {
        case ActionTypes.CURRENT_STUDIO_LOADED:
            return {
                ...action.studio,
                privateGroups: keyBy(action.studio.privateGroups, "id")
            };
        case ActionTypes.PUBLIC_GROUP_CREATED:
            const groups = cloneDeep(state.groups);
            groups.push(action.publicGroup);
            return {
                ...state,
                groups
            }
        case ActionTypes.STUDIO_TAG_CREATED:
            const tags = cloneDeep(state.tags);
            tags.push(action.tag);
            return {
                ...state,
                tags,
            }
        case ActionTypes.PRIVATE_GROUP_LOADED:
            return {
                ...state,
                privateGroups: {
                    ...state.privateGroups,
                    [action.privateGroup.id] : action.privateGroup,
                }
            }
        default:
            return state;
    }
}

function studioSelectOptions(state = {}, action) {
    switch (action.type) {
        case ActionTypes.STUDIO_OPTIONS_LOADED:
            return keyBy(action.studios, "id");
        default:
            return state;
    }
}

function chatPopupShowing(state=false, action) {
    switch (action.type) {
        case ActionTypes.OPEN_CHAT_POPUP:
            return true;
        case ActionTypes.CLOSE_CHAT_POPUP:
            return false;
        default:
            return state;
    }
}

function notifications(state={}, action) {
    switch (action.type) {
        case ActionTypes.NOTIFICATIONS_LOADED:
            // return action.notifications;
            return {
                ...state,
                ...keyBy(action.notifications, "id"),
            };
        case ActionTypes.NOTIFICATIONS_READ:
            // return mark all notifications as read
            return {};
        case ActionTypes.NOTIFICATION_UPDATED:
            return {
                ...state,
                [action.notification.id]: action.notification,
            };
        default:
            return state;
    }
}

// Used to show the "community color" across the top of the nav bar
function activeFeedId(state={}, action) {
    switch (action.type) {
        case ActionTypes.SET_ACTIVE_FEED_ID:
            return {
                communityId: action.communityId || null,
                communities: action.communities || [],
                privateGroupId: action.privateGroupId || null,
                groupId: action.groupId || null,
            };
        case ActionTypes.POST_DETAILS_LOADED:
            return {
                communityId: action.post.community || null,
                communities: action.post.communities || [],
                privateGroupId: action.post.privateGroup || null,
                groupId: action.post.group || null,
            }
        default:
            return state;
    }
}

function isMessageSending(state=false, action) {
    switch (action.type) {
        case ActionTypes.START_MESSAGE_SENDING:
            return true;
        case ActionTypes.MESSAGE_SENT:
            return false;
        default:
            return state;
    }
}

function loadingUserEvaluation(state=false, action) {
    switch (action.type) {
        case ActionTypes.START_LOAD_USER_EVALUATION:
            return true;
        case ActionTypes.USER_EVALUATION_LOADED:
            return false;
        default:
            return state;
    }
}

function freeTrialConverted(state = false, action) {
    switch(action.type) {
        case ActionTypes.USER_TRIAL_CONVERTED:
            return true;
        case ActionTypes.CLEAR_USER_TRIAL_CONVERTED:
            return false;
        default:
            return state;
    }
}

function memberRejoin(state = false, action) {
    switch (action.type) {
        case ActionTypes.USER_REJOIN_STUDIO:
            return true;
        default:
            return state;
    }
}

function freeTrialTourState(state=FreeTrialTourStates.NOT_STARTED, action) {
    switch (action.type) {
        case ActionTypes.FREE_TRIAL_TOUR_STARTED:
        case ActionTypes.FREE_TRIAL_TOUR_COMPLETE:
        case ActionTypes.FREE_TRIAL_TOUR_CLOSED:
        case ActionTypes.FREE_TRIAL_TOUR_NOT_STARTED:
            return action.status;
        default:
            return state;
    }
}

function windowIsActive(state = true, action) {
    switch (action.type) {
        case ActionTypes.WINDOW_BLUR_EVENT:
            return false;
        case ActionTypes.WINDOW_FOCUS_EVENT:
            return true;
        default:
            return state;
    }
}

function evalReminderDismissed(state = false, action) {
    switch (action.type) {
        case ActionTypes.EVALUATION_REMINDER_DISMISSED:
            return true
        case ActionTypes.CLEAR_EVALUATION_REMINDER:
        case ActionTypes.CURRENT_USER_LOADED:
            return false
        default:
            return state;
    }
}

function posterSuccess(state = {}, action) {
    switch (action.type) {
        case ActionTypes.COMMENT_POSTED:
        case ActionTypes.REPLY_POSTED:
        case ActionTypes.POST_CREATED:
        case ActionTypes.PROMO_PAGE_CREATED:
        case ActionTypes.PROMO_PAGE_UPDATED:
        case ActionTypes.BLOG_PAGE_CREATED:
        case ActionTypes.BLOG_PAGE_UPDATED:
        case ActionTypes.SITE_NOTIFICATION_CREATED:
        case ActionTypes.SITE_NOTIFICATION_UPDATED:
        case ActionTypes.EVENT_CREATED:
        case ActionTypes.EVENT_UPDATED:
        case ActionTypes.RESET_TEXT:
            return {
                ...state,
                [action.posterId]: true,
            };
        case ActionTypes.USER_EVALUATION_UPDATED:
        case ActionTypes.USER_EVALUATION_FIELD_CREATED:
        case ActionTypes.USER_EVALUATION_FIELD_UPDATED:
        case ActionTypes.QUOTE_CREATED:
        case ActionTypes.QUOTE_UPDATED:
            return {
                ...state,
                [action.draftId]: true,
            };
        case ActionTypes.CLEAR_SUCCESS:
            return {
                ...state,
                [action.posterId]: false,
            };
        default:
            return state;
    }
}

function siteNotifications(state = {}, action) {
    switch (action.type) {
        case ActionTypes.ACTIVE_SITE_NOTIFICATIONS_LOADED:
            return keyBy(action.siteNotifications, "id");
        case ActionTypes.ACTIVE_SITE_NOTIFICATION_DISMISSED:
            return omit(state, action.siteNotificationId);
        default:
            return state;
    }
}

export default combineReducers({
    activeFeedId,
    bustCache,
    chatPopupShowing,
    commentSearchResults,
    creatingUserEvaluation,
    currentStudio,
    evalReminderDismissed,
    eventSearchResults,
    freeTrialConverted,
    freeTrialTourState,
    isMessageSending,
    lastCommentId,
    lastEventId,
    lastPostId,
    lastReplyIds,
    loadingFeed,
    loadingUserEvaluation,
    memberRejoin,
    notifications,
    numberOfReplies,
    postSearchResults,
    posterSuccess,
    renderMode,
    savingUserProfile,
    searchPageLoadingState,
    siteNotifications,
    studioSelectOptions,
    userSearchResults,
    videoSearchResults,
    windowIsActive,
    windowSize,
});
